export const DEVICE_STATUS = {
    AVAILABLE: 'available',
    ASSIGNED: 'assigned',
    IN_USE: 'in_use',
};

export const DEVICE_PROVIDER = {
  CODEBOX: 'codebox',
  SENTRILOCK: 'sentrilock',
};
